import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciAirKeys from '../../../constants/locale/key/AciAir'
import CngSection from '../../../components/cngcomponents/CngSection'
import FormProperties from '../aciaircommon/AciAirManifestQtyListFormProperties'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const { button: { CngIconButton } } = components

const { initialValues } = FormProperties.formikProps

function ManifestQuantityListSection() {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  
  const aciAirManifestQtyList = watch('aciAirManifestQtyList')
  const generatedIndexKey = useRef(aciAirManifestQtyList.length)
  const isMaxAmountReached = aciAirManifestQtyList.length >= 9
  const maxAmountMessage = 'Only 9 records allowed'

  function makeTranslatedTextsObject() {
    const aciAirManifestQtyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.TITLE
    )

    return { aciAirManifestQtyList }
  }

  function handleAddManifestQuality(data) {
    const manifestQuantity = { ...data, _id: generatedIndexKey.current++ }
    const aciAirManifestQtyList = [...getValues('aciAirManifestQtyList'), manifestQuantity]

    setValue('aciAirManifestQtyList', aciAirManifestQtyList)
  }

  function handleDeleteManifestQuality(index) {
    const aciAirManifestQtyList = [...getValues('aciAirManifestQtyList')]

    aciAirManifestQtyList.splice(index, 1)

    setValue('aciAirManifestQtyList', aciAirManifestQtyList)
  }

  return (
    <CngSection
      title={translatedTextsObject.aciAirManifestQtyList}
      subheader={
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
      }
    >
      <Grid container spacing={1}>
        {_.isEmpty(aciAirManifestQtyList) ? (
          <Grid item xs='auto'>
            <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
              <span>
                <CngIconButton
                  disabled={isMaxAmountReached}
                  icon={['fal', 'plus']}
                  onClick={() => handleAddManifestQuality(initialValues)}
                  size='small'
                  type='outlined'
                />
              </span>
            </Tooltip>
          </Grid>
        ) : (
          aciAirManifestQtyList.map((manifestQuantity, index) => (
            <React.Fragment key={manifestQuantity.id || manifestQuantity._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md>
                    <FormProperties.Fields index={index} />
                  </Grid>
                  <Grid item xs={12} md='auto'>
                    <Grid container spacing={1}>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Add'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'plus']}
                              onClick={() => handleAddManifestQuality(initialValues)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title={isMaxAmountReached ? maxAmountMessage : 'Clone'}>
                          <span>
                            <CngIconButton
                              disabled={isMaxAmountReached}
                              icon={['fal', 'copy']}
                              onClick={() => handleAddManifestQuality({ ...manifestQuantity, id: undefined })}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs='auto'>
                        <Tooltip placement='bottom' title='Delete'>
                          <span>
                            <CngIconButton
                              icon={['fal', 'trash']}
                              onClick={() => handleDeleteManifestQuality(index)}
                              size='small'
                              type='outlined'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {aciAirManifestQtyList.length !== index + 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default React.memo(ManifestQuantityListSection)
