import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return Yup.object({
    formType: Yup.string().max(3).required(requiredMessage).nullable(),
    customsProc: Yup.string().max(2).required(requiredMessage).nullable(),
    convRefNo: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(7, 'Min length needed is 7')
      .max(16, 'Max length allowed is 16.')
      .matches(
        '[a-zA-Z0-9-.s]$',
        'Conveyance Reference Number cannot contain special characters.'
      )
      .test(
        'Check Flight Number',
        'Conveyance Reference Number should be prefixed with Flight Number',
        function () {
          let flightNo = this.parent['flightNo']
          let convRefNo = this.parent['convRefNo']
          if (flightNo && convRefNo) {
            if (convRefNo.startsWith(flightNo)) {
              return true
            } else {
              document.getElementById('convRefNo-helper-text').innerHTML =
                'Conveyance Reference Number should be prefixed with Flight Number.'
              return false
            }
          }
        }
      )
      .test(
        'Check Departure Date',
        'Departure Date should be in YYMMDD format.',
        function () {
          let convRefNo = this.parent['convRefNo']
          if (convRefNo !== undefined && convRefNo !== null) {
            let departureDate = convRefNo.substring(
              convRefNo.length,
              convRefNo.length - 6
            )
            if (convRefNo && convRefNo.length > 6) {
              if (moment(departureDate, 'YYMMDD', true).isValid()) {
                document.getElementById('convRefNo-helper-text').innerHTML =
                  'CRN = Flight Number + Departure Date (YYMMDD)'
                return true
              } else {
                document.getElementById('convRefNo-helper-text').innerHTML =
                  'Last 6 characters is Departure Date and should be in YYMMDD format.'
                return false
              }
            } else {
              document.getElementById('convRefNo-helper-text').innerHTML =
                'Conveyance Reference Number should be Flight Number followed by Departure Date (YYMMDD).'
              return false
            }
          }
        }
      ),
    flightNo: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(1, 'Min length needed is 1')
      .max(10, 'Max legnth allowed is 10.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed'),
    mot: Yup.string()
      .max(1, 'Max length allowed is 1.')
      .required(requiredMessage)
      .nullable(),
    carrierCode: Yup.string()
      .required(requiredMessage)
      .min(4, 'Should be a valid 4 character Carrier Code')
      .max(4, 'Should be a valid 4 character Carrier Code')
      .matches(
        '^[a-zA-Z0-9-]{4}$',
        'Carrier Code must be 4 characters long and cannot contain special characters.'
      ),
    ccn: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(5, 'Min length needed is 5.')
      .max(25, 'Max length allowed is 25.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed'),
    portOfLoading: Yup.string()
      .required(requiredMessage)
      .min(3, 'Min length needed is 3.')
      .max(4, 'Max length allowed is 4.')
      .matches('[a-zA-Z0-9]$', 'Only alphanumeric values are allowed')
      .nullable(),
    customsOff: Yup.string()
      .required(requiredMessage)
      .min(4, 'Min length needed is 4.')
      .max(4, 'Max length allowed is 4.')
      .matches('[0-9]+', 'Only numbers are allowed!')
      .nullable(),
    warehouseDischarge: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .nullable()
            .matches(
              '[a-zA-Z0-9s]$',
              'Only alphanumeric and spaces are allowed.'
            )
    ),
    rampTransferInd: Yup.string().nullable(),
    specialIns: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60.')
      .matches(
        '[a-zA-Z0-9-.s]*$',
        'Special Instructions cannot contain special characters.'
      )
      .nullable(),
    partArrivalRefNo: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(1, 'Min length needed is 1.')
      .max(6, 'Max length allowed is 6.')
      .matches(
        /^[a-z0-9]+$/i,
        'Part Arrival Reference Identifier (PARN) must comprise of only alphanumeric characters.'
      ),
    scheduledSubmissionFlag: Yup.boolean().required(requiredMessage),
    scheduleDateAndTime: Yup.string()
      .when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string()
          .required('Please select a valid date.')
          .test(
            'scheduleDateAndTime',
            'Please select a valid Future Scheduled Date Time in yyyy-mm-dd HH:mm:ss format.',
            (value) => {
              var formattedDate = moment(value).format('YYYY-MM-DD')
              var formattedToday = moment(today).format('YYYY-MM-DD')
              return moment(formattedDate).isAfter(formattedToday, 'days')
            }
          )
      })
      .nullable(),
    templateFlag: Yup.boolean().required(requiredMessage),
    templateName: Yup.string()
      .when('templateFlag', {
        is: true,
        then: Yup.string()
          .trim()
          .required(requiredMessage)
          .min(1, 'Min length needed is 1')
          .max(50, 'Max length is 50')
          .matches('[a-zA-Z0-9s]$', 'Only alphanumeric characters are allowed.')
      })
      .nullable(),
    customsOfficeDischarge: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .max(4, 'Max length allowed is 4.')
            .matches('^[0-9]+$', 'Only numbers are allowed.')
            .nullable()
    ),
    customsOfficeDischargeLoc: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.string()
            .max(4, 'Max length allowed is 4.')
            .matches('^[0-9]+$', 'Only numbers are allowed.')
            .nullable()
    ),
    hdrStatus: Yup.string().nullable(),
    messageFunction: 
    Yup.string()
      .when('hdrStatus', {
        is: (value) => value === '1000',
        then: Yup.string().test(
          'messageFunction',
          'Confirmed record cannot be submitted as Original.',
          function (value) {
            return value !== "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '20' || value === '21' || value === '28'),
        then: Yup.string().test(
          'messageFunction',
          'Error status record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '29'),
        then: Yup.string().test(
          'messageFunction',
          'Error status record should be submitted as Original / Change.',
          function (value) {
            return (value == "9" || value == "4")
          }
        )
      })      
      .when('hdrStatus', {
        is: (value) => (value === '22'),
        then: Yup.string().test(
          'messageFunction',
          'Conformance/Syntax Error status record should be submitted as Change.',
          function (value) {
            return value === "4"
          }
        )
      })
      .when('hdrStatus', {
        is: (value) => (value === '1003' || value === '1004'),
        then: Yup.string().test(
          'messageFunction',
          'Cancel Accepted/Rejected record should be submitted as Original.',
          function (value) {
            return value === "9"
          }
        )
      })
      .nullable()
      .required(requiredMessage),
    aciAirRouteList: Yup.array(),
    aciAirManifestQtyList: Yup.array(),
    aciAirEquipmentList: Yup.array(),
    aciAirStatusNotifyList: Yup.array()
  })
}

export default makeValidationSchema
